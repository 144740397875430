<script>
// import LegoDropzone from '@/components/Lego/LegoDropzone.vue'
import RenderAvatar from './RenderAvatar.vue'
import RenderImage from './RenderImage.vue'

export default {
  components: {
    RenderAvatar,
    RenderImage
    // LegoDropzone
  },

  name: 'MiAvatar',
  data() {
    return {
      hasAvatar: null,
      stringAvatar: null
    }
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    }
  },
  async mounted() {
    const response = await this.$store.getters.fetchGet({ path: `Persona/GetPersonaById/${this.userLoggedIn.id}` })
    const result = await response.json()
    if (result.avatar !== null) {
      this.hasAvatar = result.avatar,
      this.stringAvatar = result.stringAvatar
    }


    this.$watch(() => this.hasAvatar, ( next ) => {
      this.$store.getters.fetchPut({ path: `Persona/SetStateAvatar/${this.userLoggedIn.id}`, data: { avatar: next } })
      .then( response => {
        if( response.ok ) localStorage.setItem('hasAvatar', JSON.stringify({ avatar: next }) )
      })
    })
  },
  methods: {
    // async callbackAfterSubmitNewFile(self) {
    //   // self.pathAfterSaveInRemote
    //   // de debe actualizar en la tabla persona la columna ImagenPerfilUrl del usuario por su id
    // },
  }
}
</script>

<template>
  <div class="container-fluid">
    <div class="card h-100 ">
      <h1>Selección de avatar</h1>
      <section class="options">
        <div class="myCard">
          <div class="header">
            <vs-radio name="optionAvatar" :val="false" v-model="hasAvatar">
              Establecer la imagen como avatar
            </vs-radio>
          </div>
          <div class="flex-trick">
            <!-- Modal -->
            <!-- <LegoDropzone :callbackAfterSubmit="callbackAfterSubmitNewFile" :renameFile="`${userLoggedIn.numeroDocumento}ImagePerfil`" :remotePath="`users/photoPerfil/${userLoggedIn.id}`" /> -->
            <div class="wrapper-my-avatar img">
              <RenderImage :inheritedStringAvatar="stringAvatar"/>
            </div>
          
          </div>
        </div>
        <div class="myCard">
          <div class="header">
            <vs-radio name="optionAvatar" :val="true" v-model="hasAvatar">
              Establecer el avatar creado
            </vs-radio>
          </div>
          <router-link :to="{name: 'CreacionDeAvatar'}" class="wrapper-my-avatar ">
            <RenderAvatar />
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.wrapper-my-avatar {
  margin: 0 auto;
  border-radius: 50%;
  border: solid 2px #fff;
  box-shadow: 0 0 10px 0 #000;
  overflow: hidden;
  cursor: pointer;
}
.wrapper-my-avatar.img {
  width: 300px;
  height: 300px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-template-rows: 400px;
  gap: 1rem
}

.myCard {
  border: solid 1px #222;
  border-radius: 11px;
  background-color: #222;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  font-size: 1.2rem;
  display: flex;
}

.flex-trick {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.user-img {

  width: 200px;
  height: 200px;
  align-self: center;

}
</style>
